import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { Text } from '../../elements/Text/Text'

interface TextGradient {
    rotation: number
    firstColor: string
    firstColorPercent: number
    secondColor: string
    secondColorPercent: number
}

interface AccentColor {
    solidHex?: string
    useGradient: boolean
    gradient?: TextGradient
}

export interface MulticolorTextProps {
    startText?: string
    colorText: string
    endText?: string
    secondaryColor: AccentColor
    htmlElement?: 'p' | 'a' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    weight?: 'bold' | 'inherit' | undefined
    additionalStyles?: object
    fontSizeType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'auto'
}

const styles = {
    accentText: (color: AccentColor) => {
        if (color.useGradient && !isEmpty(color.gradient)) {
            const {
                rotation: ro,
                firstColor: fc,
                firstColorPercent: fcp,
                secondColor: sc,
                secondColorPercent: scp,
            } = color.gradient!
            return {
                background: `-webkit-linear-gradient(${ro}deg, ${fc} ${fcp}%, ${sc} ${scp}%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            }
        }

        return {
            color: !isEmpty(color.solidHex) ? color.solidHex! : '#000000',
        }
    },
}

export const MulticolorText = ({
    startText,
    colorText,
    endText,
    secondaryColor,
    htmlElement = 'span',
    weight = undefined,
    additionalStyles = {},
    fontSizeType = undefined,
}: MulticolorTextProps) => {
    return (
        <Text
            as={htmlElement}
            fontSizeType={fontSizeType}
            weight={weight}
            css={{ ...additionalStyles }}
            data-testid={'multicolor-text'}
        >
            {startText}{' '}
            <span style={styles.accentText(secondaryColor)}>{colorText}</span>{' '}
            {endText}
        </Text>
    )
}

export default MulticolorText
